.form {
  max-width: 320px;
  margin: 0 auto;
}

.submit {
  width: 100%;
  margin-top: 24px;
  position: relative;
}

.error {
  color: var(--other-red);
  margin-top: 8px;
}
