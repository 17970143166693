.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4px;
}

.field {
  border: 1px solid var(--border-onSurface);
  border-radius: 8px;
  cursor: text;
  outline: none;
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  padding: 13px 18px;
  min-height: 56px;
}

.field::-webkit-outer-spin-button,
.field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field[type='number'] {
  -moz-appearance: textfield;
}

.error {
  color: var(--other-red);
}
