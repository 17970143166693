.wrapper {
  padding-top: 72px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.title {
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;
}
