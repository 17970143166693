.wrapper {
  padding-top: 32px;
}

.loader {
  position: relative;
  min-height: calc(100vh - 88px - 104px);
}

.withdraw {
  margin-top: 32px;
}
