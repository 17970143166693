.btn {
  min-height: 40px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.primary {
  background-color: var(--primary-primary);
  color: var(--onPrimary-onPrimary);
}

.primary:hover {
  background-color: var(--primary-hover);
}

.primary:active {
  background-color: var(--primary-pressed);
}

.secondary {
  background-color: var(--secondary-secondary);
  color: var(--onSecondary-onSecondary);
}

.secondary:hover {
  background-color: var(--secondary-hover);
}

.secondary:active {
  background-color: var(--secondary-pressed);
}

.btn:disabled {
  background-color: var(--primary-disable);
  color: var(--onPrimary-disable);
  cursor: default;
}
